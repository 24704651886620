import {
  Address,
  ContactInfo,
  Link,
  LinkInput
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { SocialLinkUrlFields } from './Destination/types'
import { UseFormMethods } from 'react-hook-form-deprecated'
import { addProtocol } from '../profile/LinkForm/utils'
import { isDisallowedDomain } from '@dtx-company/inter-app/src/utils/isDisallowedDomain/isDisallowedDomain'
import { isValidUrl } from '@dtx-company/inter-app/src/utils/isValidUrl/isValidUrl'
import { setPreviewLink } from '@dtx-company/inter-app/src/redux/slices/linkEditor/linkEditorSlice'
import { useDebouncedCallback } from 'use-debounce'
import { useDispatch } from 'react-redux'
import linkTypes, {
  LinkMetaType,
  genericLinkMeta
} from '@dtx-company/inter-app/src/constants/linkTypes'

export const validateLink = (
  inputLink: string,
  setMeta?: (LinkMeta: LinkMetaType) => void,
  optional = false,
  restrictedLinks?: string[]
): boolean | string => {
  const link = inputLink.toLowerCase()
  if (!link && !optional) return 'URL is required'
  if (!isValidUrl(link)) return 'Please enter a valid URL'
  if (isDisallowedDomain(link)) return 'This domain is not supported'

  const cleanedLink = link.replace(/http(s)?:\/\//, '').replace('www.', '')

  const meta = linkTypes.find((linkType: LinkMetaType) => {
    try {
      const matches = cleanedLink.match(linkType.pattern)
      if (matches) {
        const title = matches?.groups?.title
        if (title) {
          linkType.title = title
        }
        return linkType
      }
    } catch (e) {
      console.error('error: ', e)
    }
    return null
  })

  if (meta && restrictedLinks?.includes(meta?.provider?.toLowerCase() as SocialLinkUrlFields)) {
    return 'This link is restricted by your organization'
  }
  if (meta) {
    setMeta && setMeta(meta)
    return true
  } else if (genericLinkMeta.pattern.test(addProtocol(link).toLowerCase())) {
    setMeta && setMeta(genericLinkMeta)
    return true
  }
  if (optional && link.length === 0) {
    return true
  }
  return "Didn't recognize that link"
}

export const validateUsernameInput = (username: string): string | boolean => {
  if (username.length === 0) return 'Username is required'
  if (username.includes('https://') || username.includes('http://'))
    return 'Please only enter your username'
  return true
}

const stringifyAddress = (address: Address | undefined): string => {
  if (!address) return ''
  const addressArray = [
    address?.streetAddress1,
    address?.streetAddress2,
    address?.city,
    address?.region,
    address?.postalCode
  ].filter(Boolean)
  const addressString = addressArray.join(' ')
  return addressString
}

const serializeCsvCol = (s?: string | null): string => (s ? `${s}` : 'n/a')
export const convertToCSV = (rows: ContactInfo[]): string => {
  return rows.reduce((acc, row) => {
    const rowArr = [
      serializeCsvCol(row.displayName),
      serializeCsvCol(row.email),
      serializeCsvCol(row.phone),
      serializeCsvCol(stringifyAddress(row.address as Address)),
      serializeCsvCol(row.gender),
      serializeCsvCol(row.age),
      serializeCsvCol(row?.custom?.value),
      serializeCsvCol(row?.custom2?.value),
      serializeCsvCol(row.capturedAt)
    ]
    const rowStr = rowArr.join(',')
    return acc + rowStr + '\r\n'
  }, 'Name,Email address,Phone number,Address,Gender,Age,Custom,Custom 2,Captured At \r\n')
}
/** @deprecated */
export const convertToGoogleSheet = (rows: ContactInfo[]): Array<Array<string>> => {
  const values = [['Name', 'Email address', 'Phone number', 'Captured At']]
  rows.forEach(row => {
    values.push([
      serializeCsvCol(row.displayName),
      serializeCsvCol(row.email),
      serializeCsvCol(row.phone),
      serializeCsvCol(row.capturedAt)
    ])
  })
  return values
}

export const useSetValueWithPreview = (
  previewLink: LinkType,
  setFormValue: UseFormMethods['setValue'],
  setLinkTheme?: (linkThemeInput: Partial<LinkType['linkTheme']>) => void
): {
  setValue: UseFormMethods['setValue']
  setLinkThemeValue: (linkThemeInput: Partial<LinkType['linkTheme']>) => void
} => {
  const dispatch = useDispatch()
  const updatePreview = useDebouncedCallback(() => {
    dispatch(
      setPreviewLink({
        previewLink
      })
    )
  }, 500)
  const setValue: UseFormMethods['setValue'] = (name, value, options): void => {
    setFormValue(name, value, options)
    updatePreview()
  }
  const setLinkThemeValue = (linkThemeInput: Partial<LinkType['linkTheme']>): void => {
    setLinkTheme?.(linkThemeInput)
    updatePreview()
  }
  return { setValue, setLinkThemeValue }
}

/**
 *
 * Diffs childLinks after an update for ids of thumbnails to remove.
 */
export const getChildLinkImageIdsToDelete = (
  linkInput: Pick<LinkInput, 'actionData' | 'childLinks'>,
  oldLinks: Pick<Link, 'id' | 'thumbNailImgUrl'>[]
): string[] => {
  if (!oldLinks?.length || !linkInput?.childLinks?.length || !linkInput?.actionData) return []

  return ((linkInput?.childLinks as Link['childLinks']) ?? [])
    ?.filter(newLink => {
      const originalLink = oldLinks?.find(oldLink => oldLink.id === newLink.id)
      const oldThumbnail = originalLink?.thumbNailImgUrl
      const newThumbnail = (linkInput.actionData as Link['actionData'])?.[newLink.provider]
        ?.thumbNailImgUrl
      return oldThumbnail && !newThumbnail
    })
    .map(link => link.id)
}
