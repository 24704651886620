import {
  CREATE_PAGE,
  CREATE_PAGE_FROM_TEMPLATE,
  DELETE_PAGE,
  EDIT_PAGE_INFO,
  RENAME_SLUG
} from '@dtx-company/flow-codegen/src/page/mutations'
import {
  CreatePageFromTemplateMutation,
  CreatePageMutation,
  DeletePageMutation,
  EditPageMutation,
  GetPageDetailsQuery,
  MutationCreatePageArgs,
  MutationCreatePageFromTemplateArgs,
  MutationDeletePageArgs,
  MutationEditPageArgs,
  RenameSlugMutation,
  RenameSlugMutationVariables
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { RootState } from '../../../redux/types'
import { deleteAvatar, uploadAvatar } from '../../../utils/rest'
import { flowpageApi } from './empty'
import { flowpageAutosaveMessaging } from '@dtx-company/true-common/src/constants/messages'
import { getCurrentJwtFromStore } from '../../../utils/jwt'
import { pageQueries } from './pageQueries'
import { sendErrorNotification, sendSuccessNotification } from '../../../utils/notifications'
import { setPageId } from '../pageEditor/pageEditorSlice'

export const pageMutations = flowpageApi.injectEndpoints({
  endpoints: builder => ({
    createPageFromTemplate: builder.mutation<
      CreatePageFromTemplateMutation,
      MutationCreatePageFromTemplateArgs
    >({
      query: variables => ({
        document: CREATE_PAGE_FROM_TEMPLATE,
        variables,
        authOnly: true
      }),
      invalidatesTags: ['pageManagement', 'pageCount']
    }),
    createPage: builder.mutation<CreatePageMutation, MutationCreatePageArgs>({
      query: ({ page }) => ({
        document: CREATE_PAGE,
        variables: {
          page
        },
        authOnly: true
      }),
      invalidatesTags: ['pageManagement', 'pageCount']
    }),
    deletePage: builder.mutation<DeletePageMutation, MutationDeletePageArgs>({
      query: ({ id }) => ({
        document: DELETE_PAGE,
        variables: {
          id
        },
        authOnly: true
      }),
      invalidatesTags: ['PageDetail', 'pageManagement', 'pageCount'],
      async onCacheEntryAdded(_, { dispatch }) {
        dispatch(setPageId(null))
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled
          sendSuccessNotification(flowpageAutosaveMessaging.deletePage.success)
        } catch {
          sendErrorNotification(flowpageAutosaveMessaging.deletePage.error)
        }
      }
    }),
    editPageInfo: builder.mutation<
      EditPageMutation,
      MutationEditPageArgs & {
        deleteImage?: boolean
        uploadImage?: string
        isTemplateEditor?: boolean
        invalidateCache?: boolean
      }
    >({
      query: ({ editPage }) => ({
        document: EDIT_PAGE_INFO,
        authOnly: true,
        variables: { editPage }
      }),
      invalidatesTags: (_, __, arg) => (arg.invalidateCache ? ['PageDetail'] : []),
      async onCacheEntryAdded({ uploadImage, deleteImage }, { getState, cacheDataLoaded }) {
        const jwt = getCurrentJwtFromStore(getState() as RootState)
        try {
          await cacheDataLoaded
          const pageId = (getState() as RootState).pageReducer.pageEditorReducer.pageId
          if (uploadImage) {
            uploadAvatar(uploadImage, pageId ?? '', jwt?.token)
          }
          if (deleteImage) {
            deleteAvatar(pageId ?? '', jwt?.token)
          }
        } catch (e) {
          console.error(e)
          sendErrorNotification('Error uploading background image')
        }
      },
      async onQueryStarted(
        { editPage, uploadImage, deleteImage, isTemplateEditor },
        { dispatch, getState, queryFulfilled }
      ) {
        const id = (getState() as RootState).pageReducer.pageEditorReducer.pageId
        const templateId = (getState() as RootState).pageReducer.pageEditorReducer.templateId
        const patchResult = dispatch(
          pageQueries.util.updateQueryData(
            'pageDetail',
            {
              id: isTemplateEditor ? templateId : id,
              isPageTemplate: Boolean(isTemplateEditor)
            },
            (draft: GetPageDetailsQuery) => {
              Object.assign(draft, {
                Page: {
                  ...draft.Page,
                  ...editPage,
                  profileImage: deleteImage ? null : uploadImage || draft.Page?.profileImage
                }
              })
            }
          )
        )
        try {
          await queryFulfilled
          sendSuccessNotification(flowpageAutosaveMessaging.editPageInfo.success)
        } catch {
          patchResult.undo()

          sendErrorNotification(flowpageAutosaveMessaging.editPageInfo.error)
        }
      }
    }),
    renameSlug: builder.mutation<RenameSlugMutation, RenameSlugMutationVariables>({
      query: variables => ({
        document: RENAME_SLUG,
        variables
      }),
      invalidatesTags: ['pageManagement'],
      async onQueryStarted({ newSlug, oldSlug }, { dispatch, getState, queryFulfilled }) {
        const id = (getState() as RootState).pageReducer.pageEditorReducer.pageId

        const patchResult = dispatch(
          pageQueries.util.updateQueryData(
            'pageDetail',
            { id, isPageTemplate: false },
            (draft: GetPageDetailsQuery) => {
              Object.assign(draft, {
                Page: {
                  ...draft.Page,
                  slugName: newSlug
                }
              })
            }
          )
        )
        try {
          await queryFulfilled
          sendSuccessNotification(`URL slug renamed from ${oldSlug} to ${newSlug}`)
        } catch {
          patchResult.undo()
          sendErrorNotification(`Error: Failed to rename url from ${oldSlug} to ${newSlug}`)
        }
      }
    })
  }),
  overrideExisting: true
})

export const {
  useEditPageInfoMutation,
  useCreatePageMutation,
  useRenameSlugMutation,
  useDeletePageMutation,
  useCreatePageFromTemplateMutation
} = pageMutations
