import {
  AllPageInfoFragment,
  CreatePageInput,
  PageType
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { ServerSideError, ServerSideErrorType } from '@dtx-company/logger'
import { convertPlaceholdersToLinks } from '../../redux/slices/pageEditor/convertPlaceholdersToLinks'
import { removeIdFromLink, serializeLink, syncFiles } from '../../redux/slices/pageEditor/util'
import { removeNullsOnOptional, unwrapOrThrow } from '@dtx-company/true-common/src/utils/objects'
import { sortLinks } from '@dtx-company/app/shared/page/src/utils/main'
import { toggleLoading } from '@dtx-company/inter-app/src/redux/slices/pageEditor/pageEditorSlice'
import { useAuthState } from '../../hooks/useAuthState'
import { useCreatePageMutation } from '../../redux/slices/flowpageApiSlice/pageMutations'
import { useCurrentTempPage } from '../useCurrentTempPage'
import { useDispatch } from 'react-redux'
import { usePlaceholders } from '../usePlaceholders'
import pick from 'lodash/pick'

export interface CreatePageProps {
  slugName?: string
  pageType?: string
  domainServiceSubdomainId?: string | null
  templateId?: string
  path?: string
  /**
   * Overrides page data that may have been set in state
   */
  pageInput?: AllPageInfoFragment
}

export type CreatePageFn = (
  props: CreatePageProps
) => Promise<{ id: string; shortUrl: string } | void>

export const useCreatePage = (): CreatePageFn => {
  const pageFromState = useCurrentTempPage()

  const placeholders = usePlaceholders()
  const dispatch = useDispatch()
  const [createPageMutation] = useCreatePageMutation()
  const { token } = useAuthState()
  return async ({ slugName, pageType, domainServiceSubdomainId, templateId, pageInput, path }) => {
    let page = pageInput || pageFromState
    if (!page) return
    const { __typename, id: _, backgroundImgUrl: _0, ...theme } = page.theme || {}

    if (slugName) {
      page = { ...page, slugName }
    }
    if (pageType) {
      page = { ...page, pageType: pageType as PageType }
    }

    const linksFromPlaceholders = convertPlaceholdersToLinks(
      placeholders || {},
      page?.links?.length ?? 0
    )
    dispatch(toggleLoading({ isLoading: true }))

    const pageLinks = sortLinks([...(page?.links ? page.links : [])])

    const res = await createPageMutation({
      page: {
        ...(templateId && {
          createdFromTemplateId: templateId
        }),
        ...pick(page, ['displayName', 'caption', 'share', 'isIndexed', 'gateId']),
        path,
        pageType: page?.pageType || undefined,
        theme,
        domainServiceSubdomainId,
        slugName: unwrapOrThrow(
          page?.slugName || slugName,
          'cannot initialize page without slugname'
        ),
        links:
          removeNullsOnOptional([...pageLinks, ...linksFromPlaceholders])
            ?.map(serializeLink)
            ?.map(removeIdFromLink) || []
      } as CreatePageInput
    })

    if ('error' in res) {
      const error = res.error
      const errorMsg = `Error creating page ${error.message}`
      throw new ServerSideError({
        ...error,
        message: errorMsg || '',
        code: error.code,
        name: 'createPage',
        endpoint: 'graphql/file',
        type: ServerSideErrorType.ServerSideOtherError
      })
    }
    const id = res.data.createPage.id
    const shortUrl = res.data.createPage.shortUrl

    if (!id) {
      throw new Error(`Error creating page No page id`)
    }
    await syncFiles(
      {
        ...page,
        // update local links with the link id returned in the create page response
        links: pageLinks?.map((link, i) => {
          return {
            ...link,
            id: res.data.createPage?.links?.[i].id || link.id
          }
        }),
        id
      },
      true,
      token
    )
    dispatch(toggleLoading({ isLoading: false }))
    return { id, shortUrl }
  }
}
