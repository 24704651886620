import { Box, Stack, Switch, Typography } from '@dtx-company/design-system/src'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { DEFAULT_PAGE_ID } from '@dtx-company/true-common/src/constants/page'
import { UnauthSlot } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { useCreateOrUpdateRemoveFCBrandingSlot } from './hooks/useCreateOrUpdateRemoveFCBranding'
import CircularProgress from '@mui/material/CircularProgress'
import InfoIcon from '@mui/icons-material/Info'
import useTranslation from 'next-translate/useTranslation'

const UnpublishedPageWarning = (): JSX.Element => {
  const { t } = useTranslation('flowpage')
  return (
    <Stack direction={'row'} gap={4} alignItems="center" sx={{ mt: 4 }}>
      <InfoIcon color={'error'} />
      <Typography color={'error'}>{t('removeBranding.unpublishedWarning')}</Typography>
    </Stack>
  )
}

export interface RemoveBrandingProps {
  pageId: string
  disabled: boolean
  existingRemoveBrandingSlot?: UnauthSlot
}

export const RemoveBranding: FC<RemoveBrandingProps> = ({
  pageId,
  disabled = false,
  existingRemoveBrandingSlot
}) => {
  const { t } = useTranslation('flowpage')
  const [isBrandingOn, setIsBrandingOn] = useState(false)
  const isUnpublishedPage = pageId === DEFAULT_PAGE_ID
  const existingRemoveBrandingSlotIsEnabled = existingRemoveBrandingSlot?.data?.isEnabled

  const { isLoading, createOrUpdateRemoveFCBrandingSlot } = useCreateOrUpdateRemoveFCBrandingSlot({
    pageId
  })

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const checked = e.target.checked
    // Don't do anything for an unpublished page
    if (isUnpublishedPage) return

    createOrUpdateRemoveFCBrandingSlot({
      pageId,
      slotId: existingRemoveBrandingSlot?.id,
      enabled: checked
    })
  }

  useEffect(() => {
    // When value changed in the API set the local state as well
    if (existingRemoveBrandingSlotIsEnabled !== undefined)
      setIsBrandingOn(existingRemoveBrandingSlotIsEnabled)
  }, [existingRemoveBrandingSlotIsEnabled])

  return (
    <>
      <Box sx={{ opacity: disabled ? 0.35 : 1 }}>
        <Typography id="remove-branding">{t('removeBranding.description')}</Typography>
      </Box>
      <Switch
        disabled={disabled || isLoading || isUnpublishedPage}
        checked={isBrandingOn}
        label={isBrandingOn ? t('common.yes') : t('common.no')}
        onChange={onChange}
        data-testid="remove-branding-toggle"
      />
      {isLoading && <CircularProgress size={30} />}
      {isUnpublishedPage && <UnpublishedPageWarning />}
    </>
  )
}
