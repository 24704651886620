const disallowedDomains = new Set([
  'weebly.com',
  'webwav.dev',
  'wixsite.com',
  'blogspot.com',
  'wixstudio.com'
])

export const isDisallowedDomain = (url: string): boolean => {
  let domain, topDomain
  const hostname = new URL(url.startsWith('http') ? url : `https://${url}`).hostname
  const segments = hostname.split('.')

  if (segments.length === 2) {
    domain = segments[0]
    topDomain = segments[1]
  } else {
    domain = segments[1]
    topDomain = segments[2]
  }

  return disallowedDomains.has([domain, topDomain].join('.'))
}
