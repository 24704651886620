import * as React from 'react'
import {
  FormControlLabel,
  FormControlLabelProps
} from '../../internal-components/FormControlLabel/FormControlLabel'
import { default as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material/Switch'
import { forwardRef } from 'react'
import { visuallyHidden } from '@mui/utils'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleIcon from '@mui/icons-material/Circle'
import clsx from 'clsx'

export type SwitchProps = Pick<
  MuiSwitchProps,
  | 'checked'
  | 'defaultChecked'
  | 'disabled'
  | 'edge'
  | 'id'
  | 'inputProps'
  | 'inputRef'
  | 'name'
  | 'onChange'
  | 'required'
  | 'size'
  | 'value'
> &
  Pick<FormControlLabelProps, 'label'> & {
    /**
     * label will be hidden from display but accessibile by screen readers
     */
    hiddenLabel?: boolean
    labelPlacement?: 'start' | 'end' | 'bottom'
    /** Whether the switch is read-only, in which case we display a not-allowed
     * cursor and disable hover effects. The element will still be accessible via
     * assistive technology but the user won't be able to change its state */
    readOnly?: boolean
  }

/**
 * Switches are the preferred way to adjust settings on mobile. The option that the switch controls, as well as the state it's in, should be made clear from the corresponding inline label.
 */
export const Switch = forwardRef<HTMLLabelElement, SwitchProps>(
  (props: SwitchProps, ref): JSX.Element => {
    const { hiddenLabel, label, labelPlacement, size, readOnly, ...rest } = props

    const inputPropsIfReadOnly = (() =>
      readOnly && {
        // Pass aria-disabled = true so that assistive tech lets the user know they can't
        // change the value of the switch. This is preferred over `disabled` because `disabled`
        // makes the element inaccessible
        'aria-disabled': true,
        // Disable click handler to make the checkbox truly read-only
        onClick: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => e.preventDefault()
      })()

    return (
      <FormControlLabel
        aria-readonly={readOnly}
        control={
          <MuiSwitch
            {...rest}
            readOnly={readOnly}
            className={clsx({ 'Mui-disabled': readOnly })}
            color="default"
            size={size}
            icon={<CircleIcon fontSize="small" htmlColor="#000" className="circleIcon" />}
            checkedIcon={<CheckCircleIcon fontSize={size} className="svgIcon-checked" />}
            inputProps={{ ...inputPropsIfReadOnly, role: 'switch' }}
          />
        }
        componentsProps={{
          typography: {
            variant: size === 'medium' ? 'labelLarge' : 'labelMedium',
            sx: hiddenLabel ? { ...visuallyHidden } : undefined
          }
        }}
        ref={ref}
        label={label}
        labelPlacement={labelPlacement}
      />
    )
  }
)

Switch.displayName = 'Switch'
