import * as Yup from 'yup'
import { CSSProperties, ChangeEvent, FC, ReactNode, useCallback } from 'react'
import { FLOWPAGE_SLUG_REGEX } from '@app/common/src/constants/regex'
import { FormikFieldProps } from '../Formik/FormikTextInput.types'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input/index'
import { SLUG_RESERVED } from '@dtx-company/flow-codegen/src/page/queries'
import {
  SlugReservedQuery,
  SlugReservedQueryVariables
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { flowpageRoot } from '../../constants'
import { getGqlError } from '@app/code/src/utils/errors'
import { logger } from '@dtx-company/logger'
import { pageGqlFetcher } from '@dtx-company/inter-app/src/services/gqlFetcher'
import { sendErrorNotification } from '@dtx-company/inter-app/src/utils/notifications'
import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'

export const SlugInputFormik: FC<FormikFieldProps> = props => {
  const {
    form: { setFieldValue },
    field: { name, value },
    label
  } = props
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      setFieldValue(name, value ? value.toLowerCase().replace(' ', '') : '')
    },
    [setFieldValue, name]
  )

  return (
    <Input
      margin="8px 0px"
      type="text"
      maxWidth="100%"
      startAdornment={<Text>{flowpageRoot}</Text>}
      value={value}
      placeholder={label?.toString()}
      onChange={onChange}
    />
  )
}

interface FileUploadProps {
  handleUpload: (arg0: ChangeEvent<HTMLInputElement>) => void | Promise<void>
  id?: string
  accept?: string
  multiple?: boolean
  position?: CSSProperties['position']
  width?: CSSProperties['width']
  children?: ReactNode
}

const StyledFileUploadInput = styled.input`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  cursor: pointer;
  height: 100%;
  opacity: 0;
`

export const FileUploadInput: FC<FileUploadProps> = ({
  // id, TODO: Revisit usage of htmlFor in linking <label> with <input>; This was causing errors at uploads
  handleUpload,
  accept,
  multiple,
  children,
  position = 'initial',
  width
}) => (
  <label style={{ cursor: 'pointer', position, width }}>
    {children}
    <StyledFileUploadInput
      type="file"
      accept={accept || 'image/png,image/jpeg,image/svg'}
      multiple={multiple || false}
      onChange={e => {
        handleUpload(e)
        /** Workaround for a bug when uploading the same file twice */
        e.target.value = ''
      }}
      data-testid="FileUploadInput"
    />
  </label>
)

export function useSlugValidation({
  submitEvent,
  invalidEvent
}: {
  invalidEvent?: () => void
  submitEvent?: () => void
}): Yup.Schema<{ slugName: string }> {
  const { t } = useTranslation('pages')
  return Yup.object({
    slugName: Yup.string()
      .required(t('SlugValidation.nameRequired'))
      .matches(FLOWPAGE_SLUG_REGEX, t('SlugValidation.noSpecialCharacters'))
      .notOneOf(process.env.RESERVED_PAGES?.split(',') ?? [], t('SlugValidation.linkReserved'))
      .test('slug', t('SlugValidation.flowpageExists'), async function (this, value) {
        const { createError, path } = this
        try {
          submitEvent?.()

          const { slugReserved } = await pageGqlFetcher<
            SlugReservedQuery,
            SlugReservedQueryVariables
          >(SLUG_RESERVED, {
            slug: value
          })

          if (slugReserved) {
            invalidEvent?.()
            return createError({ path, message: t('SlugValidation.nameReserved') })
          }
          return true
        } catch (e) {
          logger.logError(e, {
            team: 'PLG',
            componentStack: 'useChangeSubdomainSlugState'
          })

          const { message } = getGqlError(e, false)
          if (
            message === 'This name is reserved. Please contact support for verification to claim'
          ) {
            sendErrorNotification(t('SlugValidation.reservedErrorNotification'))
            return createError({ path, message: t('SlugValidation.nameReserved') })
          } else {
            sendErrorNotification(t('SlugValidation.somethingWentWrong'))
            return createError({ path, message: 'Something went wrong' })
          }
        }
      })
  })
}
