import { APP_STORE_LINK_IMAGE_URL } from '../../../../constants/flowpage'
import {
  EditLinkMutation,
  MutationEditLinkArgs
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { GraphqlRequestBaseQuery } from '../utils'
import { MutationCacheLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { RootState } from '../../../../redux/types'
import {
  deleteChildlinkThumbnails,
  deleteLinkThumbnail,
  uploadChildlinkThumbnails,
  uploadLinkThumbnail,
  uploadMediaFile,
  uploadPdf
} from '../../../../utils/rest'
import { getCurrentJwtFromStore } from '../../../../utils/jwt'
import { getValidatedPDFActionData } from '../../../../utils/flowpage'
import { isFileString } from '@dtx-company/true-common/src/utils/blob'

export type OnEditLinkMutationCacheEntryAddedArgs = MutationEditLinkArgs & {
  editImage?: string | undefined
  deleteImage: boolean
  currentImage?: string | undefined
  isTemplateEditor?: boolean | undefined
  childLinkImageIdsToDelete?: string[] | undefined
}

export type OnEditLinkMutationCacheEntryAddedApi = MutationCacheLifecycleApi<
  OnEditLinkMutationCacheEntryAddedArgs,
  GraphqlRequestBaseQuery,
  EditLinkMutation,
  'flowpageApi'
>

export const onEditLinkMutationCacheEntryAdded = async (
  {
    linkId,
    editImage,
    deleteImage = false,
    currentImage,
    linkInput,
    childLinkImageIdsToDelete
  }: OnEditLinkMutationCacheEntryAddedArgs,
  { cacheDataLoaded, getState }: OnEditLinkMutationCacheEntryAddedApi
): Promise<void> => {
  const originalLinkId = linkId
  linkId = (await cacheDataLoaded)?.data?.editLink?.id || linkId
  const jwt = getCurrentJwtFromStore(getState() as RootState)
  const token = jwt?.token
  if (editImage && editImage !== APP_STORE_LINK_IMAGE_URL) {
    uploadLinkThumbnail(linkId, editImage, token)
  } else if (deleteImage) {
    deleteLinkThumbnail(linkId, token)
  } else if (linkId !== originalLinkId && currentImage) {
    // if new link is created and the image not changed, carry original image to new link
    uploadLinkThumbnail(linkId, currentImage, token, true)
  }
  if (linkInput.type === 'pdf' && linkInput.id) {
    const url = getValidatedPDFActionData(linkInput.actionData).link
    if (isFileString(url)) {
      await uploadMediaFile(linkInput.id, url, token)
    }
  }
  if (linkInput.type === 'contactCollection' && linkInput.id) {
    const url = linkInput.actionData.link
    if (isFileString(url)) {
      await uploadPdf(linkInput.id, url, token)
    }
  }
  if (linkInput.type === 'socialLink' && linkInput.id) {
    await uploadChildlinkThumbnails(token, (await cacheDataLoaded).data.editLink)
    if (childLinkImageIdsToDelete?.length) {
      await deleteChildlinkThumbnails(token, childLinkImageIdsToDelete)
    }
  }
}
