import {
  EditLinkMutation,
  MutationEditLinkArgs
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { GraphqlRequestBaseQuery } from '../utils'
import { MutationLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { RootState } from '../../../../redux/types'
import { flowpageAutosaveMessaging } from '@dtx-company/true-common/src/constants/messages'
import { logger } from '@dtx-company/logger'
import { pageQueries } from '../pageQueries'
import {
  sendErrorNotification,
  sendSuccessNotification
} from '@dtx-company/inter-app/src/utils/notifications'

export type OnEditLinkMutationQueryStartedArgs = MutationEditLinkArgs & {
  editImage?: string
  deleteImage: boolean
  currentImage?: string
  isTemplateEditor?: boolean
  childLinkImageIdsToDelete?: string[]
}

export type OnEditLinkMutationQueryStartedApi = MutationLifecycleApi<
  OnEditLinkMutationQueryStartedArgs,
  GraphqlRequestBaseQuery,
  EditLinkMutation,
  'flowpageApi'
>

export const onEditLinkMutationQueryStarted = async (
  {
    linkId,
    linkInput,
    editImage,
    deleteImage,
    isTemplateEditor
  }: OnEditLinkMutationQueryStartedArgs,
  { dispatch, queryFulfilled, getState }: OnEditLinkMutationQueryStartedApi
): Promise<void> => {
  const patchResult = dispatch(
    pageQueries.util.updateQueryData(
      'pageDetail',
      {
        id: isTemplateEditor
          ? (getState() as RootState).pageReducer.pageEditorReducer.templateId
          : (getState() as RootState).pageReducer.pageEditorReducer.pageId,
        isPageTemplate: Boolean(isTemplateEditor)
      },
      draft => {
        const updatedLinks = draft.Page?.links?.map(link => {
          const childLinks = (linkInput?.childLinks ?? []).map(child => {
            return {
              ...child,
              thumbNailImgUrl: linkInput.actionData[child.provider]?.thumbNailImgUrl ?? null
            }
          })
          return link?.id === linkId
            ? {
                ...link,
                ...linkInput,
                thumbNailImgUrl: deleteImage ? null : editImage || link?.thumbNailImgUrl,
                childLinks
              }
            : link
        })
        Object.assign(draft, {
          Page: { ...draft.Page, links: updatedLinks || [] }
        })
      }
    )
  )

  try {
    const resp = await queryFulfilled
    // after the query completes, if a new link was created, ensure that the new link id
    // replaces the old id on the link in state
    dispatch(
      pageQueries.util.updateQueryData(
        'pageDetail',
        {
          id: isTemplateEditor
            ? (getState() as RootState).pageReducer.pageEditorReducer.templateId
            : (getState() as RootState).pageReducer.pageEditorReducer.pageId,
          isPageTemplate: Boolean(isTemplateEditor)
        },
        draft => {
          const updatedLinks = draft.Page?.links?.map(link => {
            return link?.id === linkId
              ? {
                  ...link,
                  id: resp.data.editLink.id
                }
              : link
          })
          Object.assign(draft, {
            Page: { ...draft.Page, links: updatedLinks || [] }
          })
        }
      )
    )
    sendSuccessNotification(flowpageAutosaveMessaging.editLink.success)
  } catch (err) {
    patchResult.undo()
    sendErrorNotification(flowpageAutosaveMessaging.editLink.error)
    logger.logError(err, { team: 'PLG', technicalArea: 'flowpage' })
  }
}
