import { FC } from 'react'
import {
  default as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps
} from '@mui/material/FormControlLabel'
import { MuiStyleSxProps } from '../../types/mui.types'

export type FormControlLabelProps = Omit<MuiFormControlLabelProps, MuiStyleSxProps>

export const FormControlLabel: FC<FormControlLabelProps> = ({ ...props }) => (
  <MuiFormControlLabel {...props} />
)
