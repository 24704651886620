import { Box, Button, Stack } from '@dtx-company/design-system/src'
import { ChangeEvent, FC } from 'react'
import { FileUploadInput } from '../../util/SettingsUtil'
import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { stopPropagationEvents } from '../PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { useBackgroundImageSelector } from './hooks'
import { useCurrentPagePermissions } from '../../../utils/useGetCurrentPagePermissions'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

interface CardProps {
  page: PageType | undefined
  disabled?: boolean
}

export const BackgroundImage: FC<CardProps> = ({ page, disabled }) => {
  const { isViewer, BgImage, handleDeleteImage, handleUploadImage, bgImageText, bgImage } =
    useBackgroundImageSelector({ page })
  const { hasViewOnlyAccess } = useCurrentPagePermissions()
  const viewOnly = isViewer || hasViewOnlyAccess || disabled
  return (
    <Stack alignItems="center" justifyContent="center">
      <Stack
        {...stopPropagationEvents}
        direction={{ lg: 'row' }}
        gap={4}
        justifyContent={['center', 'center', 'flex-start', 'flex-start']}
        alignItems={['center', 'center', 'flex-start', 'flex-start']}
      >
        {viewOnly ? (
          <Box
            sx={{
              opacity: viewOnly ? 0.35 : 1,
              mt: 3,
              justifyContent: ['space-between', 'space-between', 'initial']
            }}
          >
            {BgImage}
          </Box>
        ) : (
          <FileUploadInput
            position="relative"
            handleUpload={(e: ChangeEvent<HTMLInputElement>) => {
              const files = e?.target?.files
              if (files && files.length > 0) {
                handleUploadImage(files[0])
                events.Flowpage_PageProfile_Added_BackgroundImage()
              }
            }}
            id="background-image-upload"
          >
            <Box sx={{ mb: 4 }}>{BgImage}</Box>
          </FileUploadInput>
        )}
        <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 3fr', gap: 4 }}>
          <Button
            color="error"
            disabled={viewOnly || !bgImage}
            onClick={e => {
              handleDeleteImage(e)
              events.Flowpage_PageProfile_Clicked_DeletedBackgroundImageButton()
            }}
            type="button"
            fullWidth
            startIcon={DeleteOutlinedIcon}
          >
            Delete
          </Button>
          {viewOnly ? (
            <Button fullWidth>{bgImageText}</Button>
          ) : (
            <FileUploadInput
              position="relative"
              width={'100%'}
              handleUpload={(e: ChangeEvent<HTMLInputElement>) => {
                const files = e?.target?.files
                if (files && files.length > 0) {
                  handleUploadImage(files[0])
                }
                events.Flowpage_PageProfile_Selected_UploadBackgroundImageButton()
              }}
              id="background-image-upload-button"
            >
              <Button fullWidth>{bgImageText}</Button>
            </FileUploadInput>
          )}
        </Box>
      </Stack>
    </Stack>
  )
}
